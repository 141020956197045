import axiosInstance from 'src/utils/axiosInstance'

const getDashboardDetailsData = async (credentials) => {
  const response = await axiosInstance.get('api/admin/auth/dashboard')
  return response
}

const getShippingTypes = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(
      `api/shipping_types${isPaginate ? '?paginate=true' : ''}`,
      {
        params: { order, orderBy, page, limit, keyword },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const createShippingType = async (shippingTypeData) => {
  const response = await axiosInstance.post(`api/shipping_types`, shippingTypeData)
  return response.data
}

const updateShippingType = async (shippingTypeData) => {
  const response = await axiosInstance.put(
    `api/shipping_types/${shippingTypeData?.id}`,
    shippingTypeData,
  )
  return response.data
}

const createDesign = async (designData) => {
  try {
    const response = await axiosInstance.post(`api/design`, designData)
    return response.data
  } catch (error) {
    throw error
  }
}

const getDesignList = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/design${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getDesignById = async (designId) => {
  try {
    const response = await axiosInstance.get(`api/design/${designId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const addColor = async (colorData) => {
  try {
    const response = await axiosInstance.post(`api/color`, colorData)
    return response.data
  } catch (error) {
    throw error
  }
}

const getColorsList = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/color${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getColorById = async (colorId) => {
  try {
    const response = await axiosInstance.get(`api/color/${colorId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const getCustomerSalesReps = async ({
  order,
  orderBy,
  page,
  limit,
  keyword,
  isPaginate = true,
}) => {
  try {
    const response = await axiosInstance.get(`api/sale-rep${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const createCustomerSalesRep = async (data) => {
  try {
    const response = await axiosInstance.post('/api/sale-rep', data)
    return response.data
  } catch (error) {
    throw error
  }
}

const deleteCustomerSalesRep = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/sale-rep/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const createCRSEntry = async (crsEntryData) => {
  try {
    const response = await axiosInstance.post('api/customer-sale-rep', crsEntryData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listCRSEntries = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(
      `api/customer-sale-rep${isPaginate ? '?paginate=true' : ''}`,
      {
        params: { order, orderBy, page, limit, keyword },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const getCRSEntryById = async (crsEntryId, isSalesRep) => {
  try {
    const response = await axiosInstance.get(
      `api/customer-sale-rep/${crsEntryId}?&isSalesRep=${isSalesRep}`,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const createUser = async (userData) => {
  try {
    const response = await axiosInstance.post('api/user', userData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listUsers = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/user${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getUserById = async (userId) => {
  try {
    const response = await axiosInstance.get(`api/user/${userId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const createStyle = async (styleData) => {
  try {
    const response = await axiosInstance.post('api/style', styleData)
    return response.data
  } catch (error) {
    throw error
  }
}

const updateStyle = async (styleData) => {
  try {
    const response = await axiosInstance.put(`api/style/${styleData?.id}`, styleData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listStyle = async ({
  order,
  orderBy,
  page,
  limit,
  orderOnly,
  keyword,
  isPaginate = true,
}) => {
  try {
    const response = await axiosInstance.get(`api/style${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword, orderOnly },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getStyleById = async (styleId) => {
  try {
    const response = await axiosInstance.get(`api/style/${styleId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const createSize = async (sizeData) => {
  try {
    const response = await axiosInstance.post('api/size', sizeData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listWarehouse = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/warehouse${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const createWarehouse = async (warehouseData) => {
  try {
    const response = await axiosInstance.post('api/warehouse', warehouseData)
    return response.data
  } catch (error) {
    throw error
  }
}
const updateWarehouse = async (warehouseData) => {
  try {
    const payload = { ...warehouseData }
    delete payload.id
    const response = await axiosInstance.put(`api/warehouse/${warehouseData?.id}`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

const getWarehouseById = async (warehouseId) => {
  try {
    const response = await axiosInstance.get(`api/warehouse/${warehouseId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const listSizes = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/size${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const listUPC = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/upc${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getSizeById = async (sizeId) => {
  try {
    const response = await axiosInstance.get(`api/size/${sizeId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const createCustomer = async (customerData) => {
  try {
    const response = await axiosInstance.post('api/customer', customerData)
    return response.data
  } catch (error) {
    throw error
  }
}

const listCustomers = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/customer${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const getCustomerById = async (customerId) => {
  try {
    const response = await axiosInstance.get(`api/customer/${customerId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const listStitches = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(
      `api/embroidery-stitches${isPaginate ? '?paginate=true' : ''}`,
      {
        params: { order, orderBy, page, limit, keyword },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const createStitches = async (stitchesData) => {
  try {
    const response = await axiosInstance.post('api/embroidery-stitches', stitchesData)
    return response.data
  } catch (error) {
    throw error
  }
}
const updateStitches = async (stitchesData) => {
  try {
    const payload = { ...stitchesData }
    delete payload.id
    const response = await axiosInstance.put(`api/embroidery-stitches/${stitchesData?.id}`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

const getStitchesById = async (stitchesId) => {
  try {
    const response = await axiosInstance.get(`api/embroidery-stitches/${stitchesId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const listColorPrices = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(
      `api/color-prices${isPaginate ? '?paginate=true' : ''}`,
      {
        params: { order, orderBy, page, limit, keyword },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const createColorPrices = async (colorPricesData) => {
  try {
    const response = await axiosInstance.post('api/color-prices', colorPricesData)
    return response.data
  } catch (error) {
    throw error
  }
}
const updateColorPrices = async (colorPricesData) => {
  try {
    const payload = { ...colorPricesData }
    delete payload.id
    const response = await axiosInstance.put(`api/color-prices/${colorPricesData?.id}`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

const getColorPricesById = async (colorPricesId) => {
  try {
    const response = await axiosInstance.get(`api/color-prices/${colorPricesId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const listMachines = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(`api/machine${isPaginate ? '?paginate=true' : ''}`, {
      params: { order, orderBy, page, limit, keyword },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const createMachine = async (machineData) => {
  try {
    const response = await axiosInstance.post('api/machine', machineData)
    return response.data
  } catch (error) {
    throw error
  }
}
const updateMachine = async (machineData) => {
  try {
    const payload = { ...machineData }
    delete payload.id
    const response = await axiosInstance.put(`api/machine/${machineData?.id}`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

const getMachineById = async (machineId) => {
  try {
    const response = await axiosInstance.get(`api/machine/${machineId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const updatePassword = async (userData) => {
  try {
    const response = await axiosInstance.put(`api/user/change-password`, userData)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

const listOtherServices = async ({ order, orderBy, page, limit, keyword, isPaginate = true }) => {
  try {
    const response = await axiosInstance.get(
      `api/other-production-proces${isPaginate ? '?paginate=true' : ''}`,
      {
        params: { order, orderBy, page, limit, keyword },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const createProduct = async (productData) => {
  try {
    const response = await axiosInstance.post('api/other-production-proces', productData)
    return response.data
  } catch (error) {
    throw error
  }
}
const updateProduct = async (productData) => {
  try {
    const payload = { ...productData }
    delete payload.id
    console.debug(payload)
    const response = await axiosInstance.put(
      `api/other-production-proces/${productData.id}`,
      payload,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const mastersService = {
  getDashboardDetailsData, // to be removed
  getShippingTypes,
  createShippingType,
  updateShippingType,
  createDesign,
  getDesignList,
  getDesignById,
  addColor,
  getColorsList,
  getColorById,
  getCustomerSalesReps,
  createCustomerSalesRep,
  deleteCustomerSalesRep,
  createCRSEntry,
  listCRSEntries,
  getCRSEntryById,
  createUser,
  listUsers,
  getUserById,
  createStyle,
  updateStyle,
  listStyle,
  getStyleById,
  createSize,
  listSizes,
  getSizeById,
  createCustomer,
  listCustomers,
  getCustomerById,
  updatePassword,
  listUPC,
  listWarehouse,
  createWarehouse,
  getWarehouseById,
  updateWarehouse,
  listStitches,

  createStitches,
  updateStitches,
  getStitchesById,
  listColorPrices,
  createColorPrices,
  updateColorPrices,
  getColorPricesById,
  listMachines,
  createMachine,
  updateMachine,
  getMachineById,
  listOtherServices,
  createProduct,
  updateProduct,
}

export default mastersService
