export function createRoleGroup(scopeList) {
  return scopeList.reduce((accumulator, current) => {
    const newGroup = { ...accumulator }
    const key = current.split('_').at(-1)
    const title = current.replace(`_${key}`, '')

    if (newGroup[title]) newGroup[title].push(current)
    else newGroup[title] = [current]

    return newGroup
  }, {})
}

export function getApiError(errors = {}, defaultMessage = 'Internal Server Error') {
  let message = defaultMessage
  console.debug('errors ==> ', errors)
  if (Object.keys(errors)?.length) {
    message = Object.values(errors)[0]
  }
  return message
}

export function getCartonData(selectedOrder = {}) {
  if (!selectedOrder || !selectedOrder?.products?.length)
    return { cartonSelectData: {}, cartonQuantity: {}, cartonIds: {} }

  const {
    cartonSelectData = {},
    cartonQuantity = {},
    cartonIds = {},
  } = selectedOrder?.products?.reduce(
    (accumulator, current) => {
      const updatedAccumulator = { ...accumulator }

      // carton ids
      updatedAccumulator.cartonIds[current.styleRefNumber] = current.styleId
      updatedAccumulator.cartonIds[current.colorName] = current.colorId

      // cartonSelection Query
      updatedAccumulator.cartonSelectData.style_numbers[current.styleRefNumber] =
        current.styleRefNumber
      updatedAccumulator.cartonSelectData.colors[current.colorName] = current.colorName

      // carton Quantities Query
      const sizes = current.sizes.reduce((sizeAcc, sizeCur) => {
        updatedAccumulator.cartonIds[sizeCur.name] = sizeCur.sizeId

        return { ...sizeAcc, [sizeCur.name]: sizeCur.quantity }
      }, {})

      if (updatedAccumulator.cartonQuantity[current.styleRefNumber]) {
        updatedAccumulator.cartonQuantity[current.styleRefNumber][current.colorName] = sizes
      } else {
        updatedAccumulator.cartonQuantity[current.styleRefNumber] = { [current.colorName]: sizes }
      }

      return updatedAccumulator
    },
    {
      cartonIds: {},
      cartonQuantity: {},
      cartonSelectData: { style_numbers: {}, colors: {}, sizes: {} },
    },
  )

  cartonSelectData.sizes = selectedOrder?.sizes?.reduce(
    (acc, size) => ({ ...acc, [size]: size }),
    {},
  )

  return { cartonSelectData, cartonQuantity, cartonIds }
}

// const convertToCSV = (objArray) => {
//   const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
//   let str = ''
//   console.debug('array...', array)

//   for (let i = 0; i < array.length; i++) {
//     let line = ''
//     for (const index in array[i]) {
//       if (line !== '') line += ','

//       line += array[i][index]
//     }
//     str += line + '\r\n'
//   }
//   console.debug('str...', str)

//   return str
// }

const convertToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  let str = ''

  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (const index in array[i]) {
      if (line !== '') line += ','

      let value = array[i][index]
      // Check if value contains a comma, wrap in double quotes if so
      if (typeof value === 'string' && value.includes(',')) {
        value = `"${value}"`
      }
      line += value
    }
    str += line + '\r\n'
  }

  return str
}

export const downloadCSV = (data = [], fileName = 'upcOrders') => {
  const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' })
  const csvURL = URL.createObjectURL(csvData)
  const link = document.createElement('a')
  link.href = csvURL
  link.download = `${fileName}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const trimObjectValues = (obj, isUpperCase) => {
  if (typeof obj !== 'object' || obj === null) {
    throw new Error('Input must be a valid object')
  }

  return Object.keys(obj).reduce((trimmedObj, key) => {
    const value = obj[key]
    // Check if the value is a string, then trim it
    trimmedObj[key] = typeof value === 'string' ? value.trim() : value
    if (isUpperCase) {
      trimmedObj[key] = typeof value === 'string' ? value.trim()?.toUpperCase() : value
    } else {
      trimmedObj[key] = typeof value === 'string' ? value.trim() : value
    }
    return trimmedObj
  }, {})
}
